import { useState } from "react";
export default function Search({ country, phone, onSearch }) {
	const countries = [
		{
			name: "Afghanistan",
			value: "af",
			code: "93",
		},
		{
			name: "Albania",
			value: "al",
			code: "355",
		},
		{
			name: "Algeria",
			value: "dz",
			code: "213",
		},
		{
			name: "American Samoa",
			value: "as",
			code: "1684",
		},
		{
			name: "Andorra",
			value: "ad",
			code: "376",
		},
		{
			name: "Angola",
			value: "ao",
			code: "244",
		},
		{
			name: "Anguilla",
			value: "ai",
			code: "1264",
		},
		{
			name: "Antigua and Barbuda",
			value: "ag",
			code: "1268",
		},
		{
			name: "Argentina",
			value: "ar",
			code: "54",
		},
		{
			name: "Armenia",
			value: "am",
			code: "374",
		},
		{
			name: "Aruba",
			value: "aw",
			code: "297",
		},
		{
			name: "Australia",
			value: "au",
			code: "61",
		},
		{
			name: "Austria",
			value: "at",
			code: "43",
		},
		{
			name: "Azerbaijan",
			value: "az",
			code: "994",
		},
		{
			name: "Bahamas",
			value: "bs",
			code: "1242",
		},
		{
			name: "Bahrain",
			value: "bh",
			code: "973",
		},
		{
			name: "Bangladesh",
			value: "bd",
			code: "880",
		},
		{
			name: "Barbados",
			value: "bb",
			code: "1246",
		},
		{
			name: "Belarus",
			value: "by",
			code: "375",
		},
		{
			name: "Belgium",
			value: "be",
			code: "32",
		},
		{
			name: "Belize",
			value: "bz",
			code: "501",
		},
		{
			name: "Benin",
			value: "bj",
			code: "229",
		},
		{
			name: "Bermuda",
			value: "bm",
			code: "1441",
		},
		{
			name: "Bhutan",
			value: "bt",
			code: "975",
		},
		{
			name: "Bolivia",
			value: "bo",
			code: "591",
		},
		{
			name: "Bosnia and Herzegovina",
			value: "ba",
			code: "387",
		},
		{
			name: "Botswana",
			value: "bw",
			code: "267",
		},
		{
			name: "Brazil",
			value: "br",
			code: "55",
		},
		{
			name: "British Indian Ocean Territory",
			value: "io",
			code: "246",
		},
		{
			name: "British Virgin Islands",
			value: "vg",
			code: "1284",
		},
		{
			name: "Brunei",
			value: "bn",
			code: "673",
		},
		{
			name: "Bulgaria",
			value: "bg",
			code: "359",
		},
		{
			name: "Burkina Faso",
			value: "bf",
			code: "226",
		},
		{
			name: "Burma-Myanmar",
			value: "mm",
			code: "95",
		},
		{
			name: "Burundi",
			value: "bi",
			code: "257",
		},
		{
			name: "Cambodia",
			value: "kh",
			code: "855",
		},
		{
			name: "Cameroon",
			value: "cm",
			code: "237",
		},
		{
			name: "Canada",
			value: "ca",
			code: "1",
		},
		{
			name: "Cape Verde",
			value: "cv",
			code: "238",
		},
		{
			name: "Cayman Islands",
			value: "ky",
			code: "1345",
		},
		{
			name: "Central African Republic",
			value: "cf",
			code: "236",
		},
		{
			name: "Chad",
			value: "td",
			code: "235",
		},
		{
			name: "Chile",
			value: "cl",
			code: "56",
		},
		{
			name: "China",
			value: "cn",
			code: "86",
		},
		{
			name: "Christmas Island",
			value: "cx",
			code: "6189",
		},
		{
			name: "Colombia",
			value: "co",
			code: "57",
		},
		{
			name: "Comoros",
			value: "km",
			code: "269",
		},
		{
			name: "Congo",
			value: "cg",
			code: "242",
		},
		{
			name: "Congo, The Democratic Republic",
			value: "cd",
			code: "243",
		},
		{
			name: "Cook Islands",
			value: "ck",
			code: "682",
		},
		{
			name: "Costa Rica",
			value: "cr",
			code: "506",
		},
		{
			name: "Croatia",
			value: "hr",
			code: "385",
		},
		{
			name: "Cuba",
			value: "cu",
			code: "53",
		},
		{
			name: "Cyprus",
			value: "cy",
			code: "357",
		},
		{
			name: "Czech Republic",
			value: "cz",
			code: "420",
		},
		{
			name: "Denmark",
			value: "dk",
			code: "45",
		},
		{
			name: "Djibouti",
			value: "dj",
			code: "253",
		},
		{
			name: "Dominica",
			value: "dm",
			code: "1767",
		},
		{
			name: "Dominican Republic",
			value: "do",
			code: "1849",
		},
		{
			name: "Dominican Republic",
			value: "do",
			code: "1829",
		},
		{
			name: "Dominican Republic",
			value: "do",
			code: "1809",
		},
		{
			name: "East Timor",
			value: "tl",
			code: "670",
		},
		{
			name: "Ecuador",
			value: "ec",
			code: "593",
		},
		{
			name: "Egypt",
			value: "eg",
			code: "20",
		},
		{
			name: "El Salvador",
			value: "sv",
			code: "503",
		},
		{
			name: "Equatorial Guinea",
			value: "gq",
			code: "240",
		},
		{
			name: "Eritrea",
			value: "er",
			code: "291",
		},
		{
			name: "Estonia",
			value: "ee",
			code: "372",
		},
		{
			name: "Ethiopia",
			value: "et",
			code: "251",
		},
		{
			name: "Faroe Islands",
			value: "fo",
			code: "298",
		},
		{
			name: "Fiji",
			value: "fj",
			code: "679",
		},
		{
			name: "Finland",
			value: "fi",
			code: "358",
		},
		{
			name: "France",
			value: "fr",
			code: "33",
		},
		{
			name: "French Guiana",
			value: "gf",
			code: "594",
		},
		{
			name: "French Polynesia",
			value: "pf",
			code: "689",
		},
		{
			name: "Gabon",
			value: "ga",
			code: "241",
		},
		{
			name: "Gambia",
			value: "gm",
			code: "220",
		},
		{
			name: "Georgia",
			value: "ge",
			code: "995",
		},
		{
			name: "Germany",
			value: "de",
			code: "49",
		},
		{
			name: "Ghana",
			value: "gh",
			code: "233",
		},
		{
			name: "Gibraltar",
			value: "gi",
			code: "350",
		},
		{
			name: "Greece",
			value: "gr",
			code: "30",
		},
		{
			name: "Greenland",
			value: "gl",
			code: "299",
		},
		{
			name: "Grenada",
			value: "gd",
			code: "1473",
		},
		{
			name: "Guadeloupe",
			value: "gp",
			code: "590",
		},
		{
			name: "Guam",
			value: "gu",
			code: "1671",
		},
		{
			name: "Guatemala",
			value: "gt",
			code: "502",
		},
		{
			name: "Guinea",
			value: "gn",
			code: "224",
		},
		{
			name: "Guinea-Bissau",
			value: "gw",
			code: "245",
		},
		{
			name: "Guyana",
			value: "gy",
			code: "592",
		},
		{
			name: "Haiti",
			value: "ht",
			code: "509",
		},
		{
			name: "Honduras",
			value: "hn",
			code: "504",
		},
		{
			name: "Hong Kong",
			value: "hk",
			code: "852",
		},
		{
			name: "Hungary",
			value: "hu",
			code: "36",
		},
		{
			name: "Iceland",
			value: "is",
			code: "354",
		},
		{
			name: "India",
			value: "in",
			code: "91",
		},
		{
			name: "Indonesia",
			value: "id",
			code: "62",
		},
		{
			name: "Iran",
			value: "ir",
			code: "98",
		},
		{
			name: "Iraq",
			value: "iq",
			code: "964",
		},
		{
			name: "Ireland",
			value: "ie",
			code: "353",
		},
		{
			name: "Israel",
			value: "il",
			code: "972",
		},
		{
			name: "Italy",
			value: "it",
			code: "39",
		},
		{
			name: "Ivory Coast",
			value: "ci",
			code: "225",
		},
		{
			name: "Jamaica",
			value: "jm",
			code: "1876",
		},
		{
			name: "Japan",
			value: "jp",
			code: "81",
		},
		{
			name: "Jordan",
			value: "jo",
			code: "962",
		},
		{
			name: "Kazakhstan",
			value: "kz",
			code: "7",
		},
		{
			name: "Kenya",
			value: "ke",
			code: "254",
		},
		{
			name: "Kiribati",
			value: "ki",
			code: "686",
		},
		{
			name: "Kuwait",
			value: "kw",
			code: "965",
		},
		{
			name: "Kyrgyzstan",
			value: "kg",
			code: "996",
		},
		{
			name: "Laos",
			value: "la",
			code: "856",
		},
		{
			name: "Latvia",
			value: "lv",
			code: "371",
		},
		{
			name: "Lebanon",
			value: "lb",
			code: "961",
		},
		{
			name: "Lesotho",
			value: "ls",
			code: "266",
		},
		{
			name: "Liberia",
			value: "lr",
			code: "231",
		},
		{
			name: "Libya",
			value: "ly",
			code: "218",
		},
		{
			name: "Liechtenstein",
			value: "li",
			code: "423",
		},
		{
			name: "Lithuania",
			value: "lt",
			code: "370",
		},
		{
			name: "Luxembourg",
			value: "lu",
			code: "352",
		},
		{
			name: "Macau",
			value: "mo",
			code: "853",
		},
		{
			name: "Macedonia",
			value: "mk",
			code: "389",
		},
		{
			name: "Madagascar",
			value: "mg",
			code: "261",
		},
		{
			name: "Malawi",
			value: "mw",
			code: "265",
		},
		{
			name: "Malaysia",
			value: "my",
			code: "60",
		},
		{
			name: "Maldives",
			value: "mv",
			code: "960",
		},
		{
			name: "Mali",
			value: "ml",
			code: "223",
		},
		{
			name: "Malta",
			value: "mt",
			code: "356",
		},
		{
			name: "Marshall Islands",
			value: "mh",
			code: "692",
		},
		{
			name: "Martinique",
			value: "mq",
			code: "596",
		},
		{
			name: "Mauritania",
			value: "mr",
			code: "222",
		},
		{
			name: "Mauritius",
			value: "mu",
			code: "230",
		},
		{
			name: "Mayotte",
			value: "yt",
			code: "262",
		},
		{
			name: "Mexico",
			value: "mx",
			code: "52",
		},
		{
			name: "Moldova",
			value: "md",
			code: "373",
		},
		{
			name: "Monaco",
			value: "mc",
			code: "377",
		},
		{
			name: "Mongolia",
			value: "mn",
			code: "976",
		},
		{
			name: "Montenegro",
			value: "me",
			code: "382",
		},
		{
			name: "Montserrat",
			value: "ms",
			code: "1664",
		},
		{
			name: "Morocco",
			value: "ma",
			code: "212",
		},
		{
			name: "Mozambique",
			value: "mz",
			code: "258",
		},
		{
			name: "Namibia",
			value: "na",
			code: "264",
		},
		{
			name: "Nauru",
			value: "nr",
			code: "674",
		},
		{
			name: "Nepal",
			value: "np",
			code: "977",
		},
		{
			name: "Netherlands",
			value: "nl",
			code: "31",
		},
		{
			name: "Curaçao",
			value: "cw",
			code: "599",
		},
		{
			name: "New Caledonia",
			value: "nc",
			code: "687",
		},
		{
			name: "New Zealand",
			value: "nz",
			code: "64",
		},
		{
			name: "Nicaragua",
			value: "ni",
			code: "505",
		},
		{
			name: "Niger",
			value: "ne",
			code: "227",
		},
		{
			name: "Nigeria",
			value: "ng",
			code: "234",
		},
		{
			name: "Niue",
			value: "nu",
			code: "683",
		},
		{
			name: "Norfolk Island",
			value: "nf",
			code: "672",
		},
		{
			name: "Northern Mariana Islands",
			value: "mp",
			code: "1670",
		},
		{
			name: "North Korea",
			value: "kp",
			code: "850",
		},
		{
			name: "Norway",
			value: "no",
			code: "47",
		},
		{
			name: "Oman",
			value: "om",
			code: "968",
		},
		{
			name: "Pakistan",
			value: "pk",
			code: "92",
		},
		{
			name: "Palau",
			value: "pw",
			code: "680",
		},
		{
			name: "Palestine",
			value: "ps",
			code: "970",
		},
		{
			name: "Panama",
			value: "pa",
			code: "507",
		},
		{
			name: "Papua New Guinea",
			value: "pg",
			code: "675",
		},
		{
			name: "Paraguay",
			value: "py",
			code: "595",
		},
		{
			name: "Peru",
			value: "pe",
			code: "51",
		},
		{
			name: "Philippines",
			value: "ph",
			code: "63",
		},
		{
			name: "Pitcairn Islands",
			value: "pn",
			code: "870",
		},
		{
			name: "Poland",
			value: "pl",
			code: "48",
		},
		{
			name: "Portugal",
			value: "pt",
			code: "351",
		},
		{
			name: "Puerto Rico",
			value: "pr",
			code: "1787",
		},
		{
			name: "Qatar",
			value: "qa",
			code: "974",
		},
		{
			name: "Réunion",
			value: "re",
			code: "262",
		},
		{
			name: "Romania",
			value: "ro",
			code: "40",
		},
		{
			name: "Russia",
			value: "ru",
			code: "7",
		},
		{
			name: "Rwanda",
			value: "rw",
			code: "250",
		},
		{
			name: "Saint Helena",
			value: "sh",
			code: "290",
		},
		{
			name: "Saint Kitts and Nevis",
			value: "kn",
			code: "1869",
		},
		{
			name: "Saint Lucia",
			value: "lc",
			code: "1758",
		},
		{
			name: "Saint Martin",
			value: "mf",
			code: "1599",
		},
		{
			name: "Saint Pierre and Miquelon",
			value: "pm",
			code: "508",
		},
		{
			name: "Saint Vincent and the Grenadines",
			value: "vc",
			code: "1784",
		},
		{
			name: "Samoa",
			value: "ws",
			code: "685",
		},
		{
			name: "San Marino",
			value: "sm",
			code: "378",
		},
		{
			name: "São Tomé and Príncipe",
			value: "st",
			code: "239",
		},
		{
			name: "Saudi Arabia",
			value: "sa",
			code: "966",
		},
		{
			name: "Senegal",
			value: "sn",
			code: "221",
		},
		{
			name: "Serbia",
			value: "rs",
			code: "381",
		},
		{
			name: "Seychelles",
			value: "sc",
			code: "248",
		},
		{
			name: "Falkland Islands",
			value: "fk",
			code: "500",
		},
		{
			name: "Sierra Leone",
			value: "sl",
			code: "232",
		},
		{
			name: "Singapore",
			value: "sg",
			code: "65",
		},
		{
			name: "Slovakia",
			value: "sk",
			code: "421",
		},
		{
			name: "Slovenia",
			value: "si",
			code: "386",
		},
		{
			name: "Solomon Islands",
			value: "sb",
			code: "677",
		},
		{
			name: "Somalia",
			value: "so",
			code: "252",
		},
		{
			name: "South Africa",
			value: "za",
			code: "27",
		},
		{
			name: "South Korea",
			value: "kr",
			code: "82",
		},
		{
			name: "South Sudan",
			value: "ss",
			code: "211",
		},
		{
			name: "Spain",
			value: "es",
			code: "34",
		},
		{
			name: "Sri Lanka",
			value: "lk",
			code: "94",
		},
		{
			name: "Sudan",
			value: "sd",
			code: "249",
		},
		{
			name: "Suriname",
			value: "sr",
			code: "597",
		},
		{
			name: "Swaziland",
			value: "sz",
			code: "268",
		},
		{
			name: "Sweden",
			value: "se",
			code: "46",
		},
		{
			name: "Switzerland",
			value: "ch",
			code: "41",
		},
		{
			name: "Syria",
			value: "sy",
			code: "963",
		},
		{
			name: "Taiwan",
			value: "tw",
			code: "886",
		},
		{
			name: "Tajikistan",
			value: "tj",
			code: "992",
		},
		{
			name: "Tanzania",
			value: "tz",
			code: "255",
		},
		{
			name: "Thailand",
			value: "th",
			code: "66",
		},
		{
			name: "Togo",
			value: "tg",
			code: "228",
		},
		{
			name: "Tokelau",
			value: "tk",
			code: "690",
		},
		{
			name: "Tonga",
			value: "to",
			code: "676",
		},
		{
			name: "Trinidad and Tobago",
			value: "tt",
			code: "1868",
		},
		{
			name: "Tunisia",
			value: "tn",
			code: "216",
		},
		{
			name: "Turkey",
			value: "tr",
			code: "90",
		},
		{
			name: "Turkmenistan",
			value: "tm",
			code: "993",
		},
		{
			name: "Turks and Caicos Islands",
			value: "tc",
			code: "1649",
		},
		{
			name: "Tuvalu",
			value: "tv",
			code: "688",
		},
		{
			name: "Uganda",
			value: "ug",
			code: "256",
		},
		{
			name: "United Kingdom",
			value: "gb",
			code: "44",
		},
		{
			name: "Ukraine",
			value: "ua",
			code: "380",
		},
		{
			name: "United Arab Emirates",
			value: "ae",
			code: "971",
		},
		{
			name: "Uruguay",
			value: "uy",
			code: "598",
		},
		{
			name: "United States",
			value: "us",
			code: "1",
		},
		{
			name: "Uzbekistan",
			value: "uz",
			code: "998",
		},
		{
			name: "Vanuatu",
			value: "vu",
			code: "678",
		},
		{
			name: "Venezuela",
			value: "ve",
			code: "58",
		},
		{
			name: "Vietnam",
			value: "vn",
			code: "84",
		},
		{
			name: "Virgin Islands",
			value: "vi",
			code: "1340",
		},
		{
			name: "Wallis and Futuna",
			value: "wf",
			code: "681",
		},
		{
			name: "Yemen",
			value: "ye",
			code: "967",
		},
		{
			name: "Zambia",
			value: "zm",
			code: "260",
		},
		{
			name: "Zimbabwe",
			value: "zw",
			code: "263",
		},
	];
	const [_country, _setCountry] = useState(country || "in");
	const [_countryCode, _setCountryCode] = useState(countries.find((c) => c.value === _country).code);
    const [_mobileNumber, _setMobileNumber] = useState(phone || "");
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSearch({
					country: _country,
					countryCode: _countryCode,
					phone: _mobileNumber,
				});
			}}
			className="flex items-center h-[62px] bg-white w-full max-w-[550px] rounded-full"
		>
			<div className="relative flex items-center text-center text-blue-500 text-sm h-full overflow-hidden font-semibold px-4 sm:px-8">
				<span>+{_countryCode}</span>
				<select
					value={_country}
					onChange={(e) => {
						_setCountry(e.target.value);
						_setCountryCode(countries.find((c) => c.value === e.target.value).code);
					}}
					className="absolute inset-0 opacity-0 cursor-pointer"
				>
					{countries.map((country, index) => (
						<option key={index} value={country.value}>
							{country.name} (+{country.code})
						</option>
					))}
				</select>
			</div>
			<input
				value={_mobileNumber}
				onChange={(e) => {
					_setMobileNumber(e.target.value);
				}}
				autoFocus
				className="h-full font-montserrat flex-1 appearance-none min-w-0 bg-transparent focus:outline-none"
				type="tel"
				placeholder="Search phone number..."
			/>
			<button disabled={!_mobileNumber} className="bg-blue-500 flex items-center justify-center text-white h-[50px] w-[50px] rounded-full p-0 m-2 hover:bg-blue-600 hover:ring-2 duration-300 disabled:ring-0 transition disabled:bg-blue-500 disabled:cursor-not-allowed" type="submit">
				<img width="20" height="20" alt="Search icon" src="/search.svg" />
			</button>
		</form>
	);
}

import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import SearchPhone from "./components/SearchPhone";
function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/search/:country/:phone" element={<SearchPhone />} />
			<Route path="*" element={<Home />} />
		</Routes>
	);
}

export default App;

import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Search from "./Search";
import Logo from "./Logo";

export default function SearchPhone() {
	const { country, phone } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	if (!country || !phone) {
		navigate("/");
	}

	useEffect(() => {
		console.log("SearchPhone: useEffect");
		fetch(`/api/search?phone=${phone}&country=${country.toUpperCase()}`)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
			})
			.catch((error) => console.log("error", error))
			.finally(() => setIsLoading(false));
	}, [country, phone]);

	return (
		<div className="bg-blue-50 min-h-screen">
			<div className="bg-white">
				<nav className="p-8 container mx-auto ">
					<Link to="/">
						<Logo classNames="text-blue-500" />
					</Link>
				</nav>
			</div>
			<main className="container mx-auto py-8 px-2 flex justify-center">
				{isLoading ? (
					<div className="flex items-center justify-center min-h-[600px]">
						<svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
					</div>
				) : (
					<Search
						country={country}
						phone={phone}
						onSearch={({ country, phone }) => {
							setIsLoading(true);
							navigate(`/search/${country}/${phone}`, { replace: true });
						}}
					/>
				)}
			</main>
		</div>
	);
}

import { useNavigate } from "react-router-dom";
import Search from "./Search";
import Logo from "./Logo";

function Home() {
	const navigate = useNavigate();

	return (
		<div className="w-full h-screen relative select-none">
			<img src="/hero.avif" alt="Hero" className="w-full h-screen object-center object-cover" />
			<div className="w-full h-screen bg-black opacity-50 absolute inset-0"></div>
			<div className="container mx-auto absolute inset-0 p-8">
				<nav>
					<Logo classNames='!h-7' />
				</nav>
				<div className="flex flex-col sm:space-y-20 space-y-12 h-full py-32">
					<h1 className="text-white sm:text-6xl font-montserrat tracking-wide text-4xl font-black max-w-2xl">The world’s Best Caller ID and Spam Blocking app</h1>
					<Search
						onSearch={({ country, phone }) => {
							navigate(`/search/${country}/${phone}`);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default Home;
